import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  VStack,
  HStack,
  Heading,
  Text,
  Slider, SliderTrack, SliderFilledTrack, SliderThumb,
  useMediaQuery,
  Select,
  Grid,
} from "@chakra-ui/react";
import Footer from './components/Footer';


const App = () => {
  const [leftFrequency, setLeftFrequency] = useState(2.5);
  const [rightFrequency, setRightFrequency] = useState(0);
  const [duration, setDuration] = useState(5);
  const [baseFrequency, setBaseFrequency] = useState(333);

  const timeoutsRef = useRef([])
  const audioStateRef = useRef({ closed: true, isPlaying: false });

  const [tempLeftFrequency, setTempLeftFrequency] = useState(leftFrequency);
  const [tempRightFrequency, setTempRightFrequency] = useState(rightFrequency);
  const [selectedAdjustment, setSelectedAdjustment] = useState(0)

  const freqValues = [0, 2.5, 5, 10, 20, 40, 80, 160];
  const keyCodes = ['0', 'A', 'B', 'C', 'D', 'E', 'F', 'G'];
  const adjustedFreqValuesRef = useRef(freqValues);
  const [percentage, setPercentage] = useState(1);
  const [adjustedBaseFrequency, setAdjustedBaseFrequency] = useState(baseFrequency);
  const [tempDuration, setTempDuration] = useState(duration);



  const baseFrequencyOptions = [
    { key: "A", value: 292 },
    { key: "B", value: 584 },
    { key: "C", value: 1168 },
    { key: "D", value: 2336 },
    { key: "E", value: 4672 },
    { key: "F", value: 9344 },
    { key: "G", value: 18688 },
    { key: "P", value: 333 },
    { key: "T", value: 40 },
  ];

  const linearToLog = (value, min, max) => {
    const minLog = Math.log(min);
    const maxLog = Math.log(max);
    const scale = (maxLog - minLog) / (max - min);
    return Math.exp(minLog + scale * (value - min));
  };

  const logToLinear = (value, min, max) => {
    const minLog = Math.log(min);
    const maxLog = Math.log(max);
    const scale = (maxLog - minLog) / (max - min);
    return (Math.log(value) - minLog) / scale + min;
  };

  const logMin = 1;
  const logMax = Math.log(160);

  const formatFrequency = (frequency) => {
    const formatted = parseFloat(frequency).toFixed(1);
    return formatted.endsWith(".0") ? parseInt(formatted, 10) : formatted;
  };



  useEffect(() => {
    const updatedBaseFrequency = baseFrequency * percentage;
    setAdjustedBaseFrequency(updatedBaseFrequency);
    // Update the adjustedFreqValuesRef.current here
  }, [baseFrequency, percentage]);

  const [isMobile] = useMediaQuery('(max-width: 480px)');


  const getKeyValue = (value) => {
    const index = adjustedFreqValuesRef.current.indexOf(value);
    return keyCodes[index];
  };

  const generateAdjustedFreqValues = (adjustmentPercentage) => {
    return freqValues.map((value) => value * (1 + adjustmentPercentage / 100));
  };

  const handleAdjustmentPercentageChange = (percentage) => {
    // setAdjustmentPercentage(percentage);
    adjustedFreqValuesRef.current = generateAdjustedFreqValues(percentage);

    // Update left and right frequency values based on the new adjusted values
    const newLeftFrequency = getClosestAllowedValue(leftFrequency, adjustedFreqValuesRef.current);
    setLeftFrequency(newLeftFrequency);
    setTempLeftFrequency(newLeftFrequency);

    const newRightFrequency = getClosestAllowedValue(rightFrequency, adjustedFreqValuesRef.current);
    setRightFrequency(newRightFrequency);
    setTempRightFrequency(newRightFrequency);
  };



  const getClosestAllowedValue = (currentValue, adjustedValues) => {
    return adjustedValues.reduce((prev, curr) =>
      Math.abs(curr - currentValue) < Math.abs(prev - currentValue) ? curr : prev
    );
  };


  const savePlaylistToLocalStorage = (playlist) => {
    localStorage.setItem("binauralPlaylist", JSON.stringify(playlist));
  };

  const loadPlaylistFromLocalStorage = () => {
    const storedPlaylist = localStorage.getItem("binauralPlaylist");
    // console.log(storedPlaylist)
    if (storedPlaylist) {
      try {
        return JSON.parse(storedPlaylist);
      } catch (error) {
        console.error("Invalid data in local storage. Clearing stored data.");
        localStorage.removeItem("binauralPlaylist");
      }
    }
    return [];
  };

  const [playlist, setPlaylist] = useState(loadPlaylistFromLocalStorage());


  const addFrequencyPair = () => {
    const updatedPlaylist = [
      ...playlist,
      {
        id: uuidv4(),
        leftFrequency,
        rightFrequency,
        duration,
        adjustedBaseFrequency
      },
    ];

    setPlaylist(updatedPlaylist);
    savePlaylistToLocalStorage(updatedPlaylist);
  };

  const removeFrequencyPair = (id) => {
    const updatedPlaylist = playlist.filter((item) => item.id !== id)
    setPlaylist(updatedPlaylist);
    savePlaylistToLocalStorage(updatedPlaylist);

  };

  function unlockAudioContext(audioCtx) {
    if (audioCtx.state !== 'suspended') return;
    const b = document.body;
    const events = ['touchstart', 'touchend', 'mousedown', 'keydown'];
    events.forEach(e => b.addEventListener(e, unlock, false));
    function unlock() {
      audioCtx.resume().then(clean);
    }
    function clean() {
      events.forEach(e => b.removeEventListener(e, unlock));
    }
  }

  const playBinaural = async (pair, audioContext) => {
    if (audioStateRef.current && !audioStateRef.current.closed) {
      audioStateRef.current.leftOscillator.stop();
      audioStateRef.current.rightOscillator.stop();
    }

    const leftOscillator = audioContext.createOscillator();
    const rightOscillator = audioContext.createOscillator();

    leftOscillator.frequency.value = pair.leftFrequency + pair.adjustedBaseFrequency;
    rightOscillator.frequency.value = pair.rightFrequency + pair.adjustedBaseFrequency;

    const leftPanner = audioContext.createStereoPanner();
    const rightPanner = audioContext.createStereoPanner();

    leftPanner.pan.value = -1;
    rightPanner.pan.value = 1;

    leftOscillator.connect(leftPanner);
    rightOscillator.connect(rightPanner);

    leftPanner.connect(audioContext.destination);
    rightPanner.connect(audioContext.destination);

    leftOscillator.start();
    rightOscillator.start();

    audioStateRef.current = {
      audioContext,
      leftOscillator,
      rightOscillator,
      closed: false,
      isPlaying: true,
    };

    return new Promise((resolve) => {
      const timeout = setTimeout(() => {
        if (!audioStateRef.current.closed) {
          leftOscillator.stop();
          rightOscillator.stop();
        }
        resolve();
      }, pair.duration * 1000);
      timeoutsRef.current.push(timeout);
    });
  };

  const playPlaylist = async () => {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioContext = new AudioContext();
    unlockAudioContext(audioContext);

    const playNextPair = async (index) => {
      if (index >= playlist.length) {
        if (audioStateRef.current && !audioStateRef.current.closed) {
          audioContext.close();
          audioStateRef.current.closed = true;
        }
        return;
      }

      await playBinaural(playlist[index], audioContext);
      playNextPair(index + 1);
    };

    playNextPair(0);
  };





  const stopPlaylist = () => {
    if (audioStateRef.current) {
      audioStateRef.current.isPlaying = false;
    }
    if (audioStateRef.current && !audioStateRef.current.closed) {
      audioStateRef.current.leftOscillator.stop();
      audioStateRef.current.rightOscillator.stop();
      audioStateRef.current.audioContext.close();
      audioStateRef.current.closed = true;
    }
    // Clear all timeouts
    timeoutsRef.current.forEach((timeout) => clearTimeout(timeout));
    timeoutsRef.current = [];
  };


  return (
    <VStack minHeight="100vh">
      <Box className="App" p={8}>
        <Heading as="h1" size="xl" mb={6}>
          Binaural Tone Generator
        </Heading>
        <VStack spacing={4} mb={4}>
          <VStack mb={4}>
            <Text>Adjust Frequency Values:</Text>
            <Box
              overflowX="scroll"
              whiteSpace="nowrap"
              width="100%"
              px={isMobile ? 1 : 0}
            >
              <HStack
                spacing={isMobile ? 1 : 2}
              >
                {[-30, -20, -10, 0, 10, 20, 30].map((value, index, array) => (
                  <Button
                    key={value}
                    size={isMobile ? 'sm' : 'md'}
                    colorScheme={selectedAdjustment === value ? 'blue' : 'gray'}
                    onClick={() => {
                      setSelectedAdjustment(value);
                      handleAdjustmentPercentageChange(value);
                    }}
                    pl={isMobile && index === 0 ? 2 : 0}
                    pr={isMobile && index === array.length - 1 ? 2 : 0}
                  >
                    {value > 0 ? `+${value}%` : `${value}%`}
                  </Button>
                ))}
              </HStack>
            </Box>


          </VStack>
          <VStack spacing={4}>

            <FormControl>
              <FormLabel>Left Frequency</FormLabel>
              <InputGroup>
                <Input
                  type="number"
                  value={tempLeftFrequency}
                  onChange={(e) => setTempLeftFrequency(parseFloat(e.target.value))}
                  onBlur={() => setLeftFrequency(tempLeftFrequency)}
                  onMouseDown={(e) => e.stopPropagation()}
                />
                <InputRightAddon children="Hz" />
              </InputGroup>

              <Slider
                aria-label="Left Frequency"
                value={logToLinear(leftFrequency, logMin, logMax)}
                min={logToLinear(1, logMin, logMax)}
                max={logToLinear(160, logMin, logMax)}
                step={0.01}
                onChange={(val) => {
                  const logValue = linearToLog(val, logMin, logMax);
                  const closestAllowedValue = getClosestAllowedValue(logValue, adjustedFreqValuesRef.current);
                  setLeftFrequency(closestAllowedValue);
                  setTempLeftFrequency(closestAllowedValue);
                }}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb fontSize="lg" boxSize="24px" children={getKeyValue(leftFrequency)} />
              </Slider>

            </FormControl>
            <FormControl>
              <FormLabel>Right Frequency</FormLabel>
              <InputGroup>
                <Input
                  type="number"
                  value={tempRightFrequency}
                  onChange={(e) => setTempRightFrequency(parseFloat(e.target.value))}
                  onBlur={() => setRightFrequency(tempRightFrequency)}
                  onMouseDown={(e) => e.stopPropagation()}

                />
                <InputRightAddon children="Hz" />
              </InputGroup>

              <Slider
                aria-label="Right Frequency"
                value={logToLinear(rightFrequency, logMin, logMax)}
                min={logToLinear(1, logMin, logMax)}
                max={logToLinear(160, logMin, logMax)}
                step={0.01}
                onChange={(val) => {
                  const logValue = linearToLog(val, logMin, logMax);
                  const closestAllowedValue = getClosestAllowedValue(logValue, adjustedFreqValuesRef.current);
                  setRightFrequency(closestAllowedValue);
                  setTempRightFrequency(closestAllowedValue);
                }}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb fontSize="lg" boxSize="24px" children={getKeyValue(rightFrequency)} />
              </Slider>


            </FormControl>

          </VStack>
          <VStack spacing={4}>


            <FormControl>
              <FormLabel>Duration (seconds)</FormLabel>
              <Input
                type="number"
                value={tempDuration}
                onChange={(e) => setTempDuration(parseFloat(e.target.value))}
                onBlur={() => setDuration(tempDuration)}
              />
              <Slider
                aria-label="Duration"
                value={duration}
                min={0}
                max={60} // Set the max duration you want to allow, for example, 600 seconds (10 minutes)
                step={1}
                onChange={(val) => {
                  setTempDuration(val)
                  setDuration(val)
                }
                }
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb fontSize="lg" boxSize="24px" children={duration} />
              </Slider>
            </FormControl>


            <FormControl>
              <FormLabel>Base Frequency</FormLabel>
              <InputGroup>
                <Select
                  value={baseFrequency}
                  onChange={(e) => setBaseFrequency(parseFloat(e.target.value))}
                  width="50%"
                >
                  {baseFrequencyOptions.map((option) => (
                    <option key={option.key} value={option.value}>
                      {option.key}
                    </option>
                  ))}
                </Select>
                <Input
                  type="number"
                  value={baseFrequency}
                  onChange={(e) => setBaseFrequency(parseFloat(e.target.value))}
                  width="50%"
                />
                <InputRightAddon children="Hz" />
                <Select
                  placeholder="Percentage"
                  defaultValue={1}
                  width="66%"
                  onChange={(e) => {
                    setPercentage(Number(e.target.value))
                  }

                  }
                >
                  <option value={0.7}>-30%</option>
                  <option value={0.8}>-20%</option>
                  <option value={0.9}>-10%</option>
                  <option value={1}>0%</option>
                  <option value={1.1}>+10%</option>
                  <option value={1.2}>+20%</option>
                  <option value={1.3}>+30%</option>
                </Select>
              </InputGroup>
            </FormControl>

          </VStack>
          <Button onClick={addFrequencyPair}>Add to Playlist</Button>
        </VStack>
        <Box mb={4}>
          <Heading as="h2" size="lg" mb={4}>
            Playlist
          </Heading>
          {playlist.map((pair) => (

            <Grid
              key={pair.id}
              mb={2}
              templateColumns="1fr auto"
              alignItems="center"
              display="flex"
              justifyContent="space-between"
            >
              <Text>
                {formatFrequency(pair.leftFrequency + pair.adjustedBaseFrequency)} Hz -{" "}
                {formatFrequency(pair.rightFrequency + pair.adjustedBaseFrequency)} Hz for{" "}
                {pair.duration} seconds
              </Text>

              <Button size="sm" onClick={() => removeFrequencyPair(pair.id)}>
                Remove
              </Button>

            </Grid>
          ))}
          <HStack mt={4}>
            <Button onClick={playPlaylist} colorScheme="blue">
              Play Playlist
            </Button>
            <Button onClick={stopPlaylist} colorScheme="red">
              Stop
            </Button>
          </HStack>
        </Box>
      </Box>
      <Footer />
    </VStack>
  );
};

export default App;