import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box as="footer" textAlign="center" p={4} bg="gray.100" borderTopWidth="1px" borderColor="gray.200">
      <Text fontSize="sm">
        &copy; {currentYear} Made with ❤ by Ignacio Giadach
      </Text>
    </Box>
  );
};

export default Footer;
